require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
//require("channels");
import $ from "jquery";
global.$ = jQuery;

require("bootstrap");

$(document).on("turbolinks:click", function (event) {
	if (event.target.getAttribute("href").charAt(0) === "#") {
		return event.preventDefault();
	}
});

function hideMenu() {
	document.getElementsByTagName("menu")[0].classList.remove("active-menu");
}

document.addEventListener("click", (event) => {
	if (event.target.className !== "toggler-btn") {
		hideMenu();
	}
});

let ready;
ready = function () {
	$("#hamburger").click(function () {
		document.getElementsByTagName("menu")[0].classList.toggle("active-menu");
	});

	$(".toggle").click(function () {
		$(this).toggleClass("active");
		$("#overlay").toggleClass("open");
	});

	$(".app a").click(function () {
		$(".overlay").removeClass("open");
	});

	$("#FreePlay").click(function () {
		$("#tabBox").addClass("activeCard1");
		$("#tabBox").removeClass("activeCard2");
		$("#tabBox").removeClass("activeCard3");
		$("#tabBox").removeClass("activeCard4");
	});
	$("#CashBack").click(function () {
		$("#tabBox").addClass("activeCard2");
		$("#tabBox").removeClass("activeCard1");
		$("#tabBox").removeClass("activeCard3");
		$("#tabBox").removeClass("activeCard4");
	});
	$("#ReferralBonus").click(function () {
		$("#tabBox").addClass("activeCard4");
		$("#tabBox").removeClass("activeCard1");
		$("#tabBox").removeClass("activeCard2");
		$("#tabBox").removeClass("activeCard3");
	});

	// scroll to matching named element in hash
	if(window.location.hash){
		var tag = document.getElementById(window.location.hash.slice(1))
		if(tag){
			setTimeout(function() {
				tag.scrollIntoView();
			}, 1000);
		}
	}

};

document.addEventListener("turbolinks:load", ready);
